import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Backdrop,
  DialogContentText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ImageAnalysis from "../Image/ImageAnalysis";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import * as React from "react";
import {
  useClusterListingsMutation,
  useAnalyzeImageQuery,
} from "../../../store/api";

const ClusterFlagDialog = (props) => {
  const [clusterListings] = useClusterListingsMutation();
  const [url, setUrl] = React.useState(undefined);
  const [analyze, setAnalyze] = React.useState(false);

  const { listingCluster, handleClose, listing } = props;

  const { data, isFetching } = useAnalyzeImageQuery(
    analyze ? { url: listingCluster.url || url } : skipToken
  );

  const handleConfirm = () => {
    if (listingCluster.hasOwnProperty("analyse")) {
      clusterListings({ type: listingCluster.type, identifier: listing });
      handleClose();
    } else {
      setAnalyze(true);
    }
  };
  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={"sm"}
      scroll="body"
      onClose={handleClose}
      aria-labelledby="Flag cluster dialog"
    >
      <DialogContent>
        {isFetching && analyze && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isFetching}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {data &&
          data.map((d, index) => {
            return <ImageAnalysis data={d} key={index} />;
          })}
        {!listingCluster.url && !listingCluster.hasOwnProperty("analyse") && (
          <React.Fragment>
            <Typography>
              Analyze any image by entering its URL below:
            </Typography>
            <FormControl fullWidth sx={{ mt: 5, mb: 5 }}>
              <TextField
                autoFocus
                fullWidth
                id="commenturl"
                label="Image URL"
                onChange={(event) => setUrl(event.target.value)}
              />
            </FormControl>
          </React.Fragment>
        )}
        {!data && (
          <DialogContentText variant="button">
            {listingCluster.hasOwnProperty("analyse")
              ? `Please confirm Description analysis request(may take several seconds to complete).`
              : `Please confirm image analysis request (may take several seconds to
          complete)`}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          {data ? "Close" : "Cancel"}
        </Button>
        {!data && (
          <Button
            autoFocus
            onClick={handleConfirm}
            disabled={!listingCluster.url && !url}
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(ClusterFlagDialog);

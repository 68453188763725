import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  Grid,
  IconButton as MuiIconButton,
  Toolbar,
  ListItemButton,
  Tooltip,
  Button,
  Link,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MuiAppBar from "@mui/material/AppBar";
import { Menu as MenuIcon } from "@mui/icons-material";
import NavbarUserDropdown from "./NavbarUserDropdown";
import { NavLink } from "react-router-dom";
import logo from "../../vendor/logo.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import MessageDialog from "../dialogs/Messages";
import { useSelector, useDispatch } from "react-redux";
import {
  setPrinting,
  setComplaintsFetching,
  setCasesFetching,
  setKeyboardMode,
  setListingFetch,
  setDomainsFetching,
  setSelectedListings,
  setSellersFetching,
} from "../../store/app";
import useAuth from "../../hooks/useAuth";
import { getExportLink } from "../../utils/export";
import { THEMES } from "../../constants";
import useTheme from "../../hooks/useTheme";

const drawerWidth = 258;

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;
  padding-bottom: 10px;
  width: 150px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;
const NavButton = styled(Button)`
  color: ${(props) => props.theme.palette.secondary.contrastText};
  min-width: 30px;
  margin-left: 5px;

  span {
    margin: 0px;
  }
`;

const Navbar = ({
  open,
  handleDrawerOpen,
  handleDrawerClose,
  context,
  toPDF,
}) => {
  const [unread, setUnread] = React.useState(0);
  const [viewMessages, setViewMessages] = React.useState(false);
  const [exportLink, setExportLink] = React.useState("");
  const dispatch = useDispatch();
  const params = useSelector((state) => state.app.params);
  const { listing, selectedListings, printing } = useSelector(
    (state) => state.app
  );

  const { user } = useAuth();
  const { theme, setTheme } = useTheme();

  const [confirmPDF, setConfirmPDF] = React.useState(false);

  const closePDFConfirmation = () => {
    setConfirmPDF(false);
  };

  React.useEffect(() => {
    if (printing) {
      let oldTheme = theme;
      if (theme !== THEMES.LIGHT) {
        setTheme(THEMES.LIGHT);
      }
      setTimeout(() => {
        toPDF().then(() => dispatch(setPrinting(false)));
        if (oldTheme !== THEMES.LIGHT) {
          setTheme(oldTheme);
        }
      }, "2000");
    }
    // eslint-disable-next-line
  }, [printing]);

  const handleRefreshListing = () => {
    dispatch(setListingFetch(true));
  };
  const handleRefreshComplaints = () => {
    dispatch(setComplaintsFetching(true));
  };
  const handleRefreshCases = () => {
    dispatch(setCasesFetching(true));
  };
  const handleRefreshSellers = () => {
    dispatch(setSellersFetching(true));
  };
  const handleRefreshDomains = () => {
    dispatch(setDomainsFetching(true));
  };
  const handleSelectAll = () => {
    if (listing?.length !== selectedListings?.length) {
      dispatch(setSelectedListings(listing?.map((l) => l._id)));
    } else {
      dispatch(setSelectedListings([]));
    }
  };
  const enterReviewMode = () => {
    dispatch(setKeyboardMode(true));
  };

  React.useEffect(() => {
    setExportLink(getExportLink(params, user, "inbox"));
  }, [params, user]);

  return (
    <React.Fragment>
      <AppBar
        style={{ display: printing ? "none" : "block" }}
        open={open}
        sx={{ boxShadow: "0px 6px 20px 12px rgb(0 0 0 / 15%)" }}
      >
        <Toolbar sx={{ height: "81px" }}>
          {open ? (
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {<ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
          ) : (
            <React.Fragment>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerOpen}
                size="large"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Brand component={NavLink} to="/">
                <img
                  src={logo}
                  alt="Authentix logo"
                  style={{ height: 42, marginTop: 8, marginRight: 4 }}
                />
              </Brand>
            </React.Fragment>
          )}
          <Grid container alignItems="center">
            <Grid item>
              <Typography
                variant="h3"
                sx={{
                  ml: 8,
                  mt: open ? 0 : 2,
                  display: "inline-block",
                  whiteSpace: "nowrap",
                }}
              >
                {context || ""}
              </Typography>
              {context === "Complaints" && (
                <>
                  <Tooltip title="Reload the current complaints">
                    <NavButton
                      variant="text"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefreshComplaints}
                    ></NavButton>
                  </Tooltip>
                </>
              )}
              {context === "Investigations" && (
                <>
                  <Tooltip title="Reload the current investigations">
                    <NavButton
                      variant="text"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefreshCases}
                    ></NavButton>
                  </Tooltip>
                </>
              )}
              {context === "Sellers" && (
                <>
                  <Tooltip title="Reload the current sellers">
                    <NavButton
                      variant="text"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefreshSellers}
                    ></NavButton>
                  </Tooltip>
                </>
              )}
              {context === "Domains" && (
                <>
                  <Tooltip title="Reload the current domains">
                    <NavButton
                      variant="text"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefreshDomains}
                    ></NavButton>
                  </Tooltip>
                </>
              )}
              {context === "Listings" && (
                <>
                  <Tooltip title="Reload the current listings">
                    <NavButton
                      variant="text"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefreshListing}
                    ></NavButton>
                  </Tooltip>
                  <Tooltip title="Download listings">
                    <NavButton
                      component={Link}
                      href={exportLink.toString()}
                      target="_blank"
                      startIcon={<CloudDownloadIcon />}
                    ></NavButton>
                  </Tooltip>
                  <Tooltip title="Keyboard mode">
                    <NavButton
                      variant="text"
                      onClick={enterReviewMode}
                      startIcon={<KeyboardIcon />}
                    ></NavButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      listing?.length !== selectedListings?.length
                        ? "Select All"
                        : "Deselect All"
                    }
                  >
                    <NavButton
                      variant="text"
                      startIcon={
                        listing?.length !== selectedListings?.length ? (
                          <CheckBoxOutlineBlankIcon />
                        ) : (
                          <CheckBoxIcon />
                        )
                      }
                      onClick={handleSelectAll}
                    ></NavButton>
                  </Tooltip>
                </>
              )}
            </Grid>
            <Grid item xs />
            {context === "Dashboard" && (
              <Grid item>
                <Tooltip title={`Capture as PDF`}>
                  <IconButton
                    disabled={printing}
                    onClick={() => {
                      setConfirmPDF(true);
                    }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tooltip title={`View messages`}>
                <NavButton
                  variant="text"
                  startIcon={
                    <Badge badgeContent={unread} color="secondary">
                      <MailIcon />
                    </Badge>
                  }
                  onClick={() => {
                    setUnread(0);
                    setViewMessages(true);
                  }}
                ></NavButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {viewMessages && (
        <MessageDialog
          open={viewMessages}
          handleClose={() => setViewMessages(false)}
        />
      )}
      {confirmPDF && (
        <Dialog
          open={confirmPDF}
          onClose={closePDFConfirmation}
          aria-labelledby="pdf-dialog-title"
          aria-describedby="pdf-dialog-description"
        >
          <DialogTitle id="pdf-dialog-title">{"Download PDF?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="pdf-dialog-description">
              The dashboard UI will be adjusted momentarily while the PDF is
              created from its content. It will return to the current view when
              complete.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closePDFConfirmation}>Cancel</Button>
            <Button
              onClick={() => {
                dispatch(setPrinting(true));
                closePDFConfirmation();
              }}
              autoFocus
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default withTheme(Navbar);

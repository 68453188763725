import * as React from "react";
import styled from "styled-components/macro";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { hasRole } from "../../utils/auth";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const { user, signOut } = useAuth();
  const [copied, setCopied] = React.useState(false);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    document.forms["logout-form"].submit();
  };

  return (
    <React.Fragment>
      <form name="logout-form" method="post" action="/logout"></form>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>

        {user && hasRole(user, ["analyst", "admin", "manager", "god"]) && (
          <CopyToClipboard
            text={user.auth}
            onCopy={() => {
              setCopied(true);
            }}
          >
            <MenuItem>{copied ? "API Key Copied!" : "Copy API Key"}</MenuItem>
          </CopyToClipboard>
        )}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DatePicker from "@mui/lab/DatePicker";
import Stack from "@mui/material/Stack";
import addDays from "date-fns/addDays";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useAuth from "../../../hooks/useAuth";
import { INFRINGEMENT_REASONS, getToi } from "../../../utils/labels";
import {
  useMakeTestPurchaseMutation,
  useGetTestPurchaseQuery,
  useGetUsersQuery,
} from "../../../store/api";
import TestPurchaseCard from "../Case/TestPurchaseCard";

export default function TestPurchaseDialog(props) {
  const { handleClose, listing } = props;

  const { data: purchase, isLoading } = useGetTestPurchaseQuery(
    listing.testPurchase ? listing.testPurchase : skipToken
  );

  const { data: users } = useGetUsersQuery();
  const { user } = useAuth();
  const [comment, setComment] = React.useState("");
  const [assignee, setAssignee] = React.useState(purchase?.assignedTo);
  const [infringement, setInfringement] = React.useState(listing?.reason || "");
  const [inputValue, setInputValue] = React.useState("");
  const [due, setDue] = React.useState(addDays(new Date(), 30));
  const [makeTestPurchase] = useMakeTestPurchaseMutation();

  const handleChange = () => {
    makeTestPurchase({
      listing: listing._id,
      brand: listing.brand,
      product: listing.product,
      comment: comment.trim().length > 0 ? comment : undefined,
      assignedTo: assignee?.value,
      status: assignee?.value ? "ASSIGNED" : "NEW",
      infringementType: infringement,
      origin: "ONLINE_LEAD",
      value: listing.price,
      platform: listing.platform,
      seller: {
        country: listing.country,
        name: listing.seller,
        websiteUrl: listing.sellerStoreUrl,
      },
      due: due,
    }).then(() => {
      handleClose();
    });
  };

  const findGroup = (groups) => {
    if (groups.includes("client")) {
      return "Clients";
    } else if (groups.includes("analyst")) {
      return "Analysts";
    }
  };

  const brandMatch = (u) => {
    return u.brands?.filter((value) => user.brands?.includes(value)).length > 0;
  };

  const getUserOptions = () => {
    let options = [];
    users
      .filter((u) => {
        return (
          brandMatch(u) &&
          (u.groups?.includes("client") || u.groups?.includes("analyst"))
        );
      })
      .forEach((user) => {
        options.push({
          label: user.name,
          value: user.providerId,
          group: findGroup(user.groups),
        });
      });
    return options.sort((a, b) => a.group.localeCompare(b.group));
  };

  return (
    <div>
      {!purchase && (
        <Dialog
          PaperProps={{ style: { overflowY: "visible" } }}
          open={listing.testPurchase === undefined}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"xs"}
          scroll="body"
        >
          <DialogTitle>Investigation</DialogTitle>

          <DialogContent style={{ overflowY: "visible" }}>
            <Stack spacing={4}>
              <DialogContentText>
                Enter instructions in the field below for this investigation:
              </DialogContentText>
              <TextField
                autoFocus
                multiline
                minRows={2}
                margin="dense"
                id="name"
                value={comment}
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setComment(event.target.value)}
              />
              <DatePicker
                label="Due date"
                value={due}
                onChange={(newValue) => {
                  setDue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              <FormControl fullWidth>
                <InputLabel id="infringement-select-label">
                  Infringement (required)
                </InputLabel>
                <Select
                  labelId="infringement-select-label"
                  id="infringement-select"
                  value={infringement}
                  label="Infringement (required)"
                  onChange={(e) => setInfringement(e.target.value)}
                >
                  {INFRINGEMENT_REASONS.map((r) => {
                    return (
                      <MenuItem value={r.value} key={r.value}>{`${getToi(
                        r.toi
                      )} : ${r.text}`}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {users && (
                <Autocomplete
                  disablePortal
                  id="assignment"
                  value={assignee?.value}
                  onChange={(_event, newValue) => {
                    setAssignee(newValue);
                  }}
                  isOptionEqualToValue={(option, value) => {
                    if (value === undefined || value.length === 0) {
                      return false;
                    }
                    return option.value === value.value;
                  }}
                  inputValue={inputValue}
                  onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={getUserOptions() || []}
                  groupBy={(option) => option.group}
                  renderInput={(params, index) => (
                    <TextField
                      {...params}
                      label="Assign to (optional)"
                      key={index}
                    />
                  )}
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>CANCEL</Button>
            <Button
              onClick={handleChange}
              disabled={comment.trim().length === 0 || infringement === ""}
            >
              SUBMIT
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {purchase && (
        <Dialog open={listing.testPurchase !== undefined} onClose={handleClose}>
          <DialogContent>
            {!isLoading && <TestPurchaseCard purchase={purchase} />}
          </DialogContent>
          <DialogActions sx={{ mt: -10 }}>
            <Button onClick={handleClose}>CLOSE</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

import React from "react";
import async from "./components/Async";

//Layout
import DashboardLayout from "./layouts/Dashboard";

//Components
const Dashboard = async(() => import("./pages/dashboards/Default"));
const Listings = async(() => import("./pages/inboxes/Listing"));
const Complaints = async(() => import("./pages/inboxes/Complaint"));
const Sellers = async(() => import("./pages/inboxes/Seller"));
const Images = async(() => import("./pages/inboxes/Image"));
const Domains = async(() => import("./pages/inboxes/Domain"));
const Cases = async(() => import("./pages/inboxes/Case"));
const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/listings",
        element: <Listings />,
      },
      {
        path: "/complaints",
        element: <Complaints />,
      },
      {
        path: "/sellers",
        element: <Sellers />,
      },
      {
        path: "/investigations",
        element: <Cases />,
      },
      {
        path: "/images",
        element: <Images />,
      },
      {
        path: "/domains",
        element: <Domains />,
      },
    ],
  },
];

export default routes;

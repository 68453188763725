import ShieldIcon from "@mui/icons-material/Shield";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import BadShieldIcon from "@mui/icons-material/GppMaybe";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import IconButton from "@mui/material/IconButton";

import { styled } from "@mui/material/styles";

export const ExpandMore = styled((props) => {
  // eslint-disable-next-line
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getPriceColor = (score) => {
  let abs = Math.abs(score);
  if (abs === 0 || (abs > 1 && abs < 1.25) || (abs < 1 && abs > 0.75)) {
    return "inherit";
  } else if ((abs > 1.25 && abs < 1.5) || (abs < 0.75 && abs > 0.5)) {
    return "orange";
  } else {
    return "red";
  }
};

export const getRiskIcon = (listing, size) => {
  if (listing.state === "AUTHORIZED_SELLER") {
    return <VerifiedUserIcon sx={{ color: "green", fontSize: size, ml: 5 }} />;
  } else if (
    (listing.score && listing.score > 2) ||
    listing.state === "INFRINGING" ||
    (listing.state === "DRAFT" && listing.toi !== undefined)
  ) {
    return <BadShieldIcon sx={{ color: "red", fontSize: size, ml: 5 }} />;
  } else if (listing.state === "GENUINE") {
    return <ShieldIcon sx={{ color: "green", fontSize: size, ml: 5 }} />;
  } else if (listing.state === "LOOKALIKE") {
    return <ShieldIcon sx={{ color: "yellow", fontSize: size, ml: 5 }} />;
  } else if (listing.state === "SELLER_HOLD") {
    return <ShieldIcon sx={{ color: "orange", fontSize: size, ml: 5 }} />;
  } else if (
    (listing.score && listing.score > 0) ||
    listing.state === "SUSPECT"
  ) {
    return <ShieldIcon sx={{ color: "yellow", fontSize: size, ml: 5 }} />;
  }
  return <ShieldOutlinedIcon sx={{ fontSize: size, ml: 5 }} />;
};

import React from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import useAuth from "../../../hooks/useAuth";
import { hasRole } from "../../../utils/auth";
import {
  useGetListingsQuery,
  useUpdateListingFieldMutation,
  useDeleteListingsMutation,
  useTakeScreenshotMutation,
  useGetMarketPlacesQuery,
  useScoreListingMutation,
} from "../../../store/api";
import { setSelectedListings } from "../../../store/app";
import ConfirmationDialog from "./ConfirmationDialog";
import ScreenshotConfirmationDialog from "./ScreenshotConfirmationDialog";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import InfringmentDialog from "./InfringementDialog";
import PolicyIcon from "@mui/icons-material/Policy";
import CrawlDialog from "../../../components/dialogs/Crawl";
import CrawlUrlDialog from "../../../components/dialogs/CrawlUrl";
import { STATUS_TYPES } from "../../../utils/labels";

const options = [
  { text: "Select One ...", value: "", state: "" },
  { text: "Suspect", value: "SU", state: "SUSPECT" },
  { text: "Authorized Seller", value: "AS", state: "AUTHORIZED_SELLER" },
  { text: "Seller Hold", value: "SH", state: "SELLER_HOLD" },
  { text: "Genuine", value: "GE", state: "GENUINE" },
  { text: "Lookalike", value: "LA", state: "LOOKALIKE" },
  { text: "Irrelevant", value: "IR", state: "IRRELEVANT" },
  { text: "Reviewed", value: "RV", state: "REVIEWED" },
  { text: "Reinstated", value: "RI", state: "REINSTATED" },
];

const ListingActions = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const selected = useSelector((state) => state.app.selectedListings);
  const params = useSelector((state) => state.app.params);
  const { data: listings } = useGetListingsQuery(user ? params : skipToken);
  const [confirm, setConfirm] = React.useState(undefined);
  const [infringement, setInfringement] = React.useState(undefined);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmScreenshot, setConfirmScreenshot] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [statusOpen, setStatusOpen] = React.useState(false);
  const [crawl, setCrawl] = React.useState(false);
  const [crawlUrl, setCrawlUrl] = React.useState(false);
  const anchorRef = React.useRef(null);
  const statusAnchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [statusSelectedIndex, setStatusSelectedIndex] = React.useState(0);
  const [comment, setComment] = React.useState("");
  const [enableComment, setEnableComment] = React.useState(true);
  const [processLike, setProcessLike] = React.useState(false);
  const [analystActionsEnabled, setAnalystActionsEnabled] =
    React.useState(true);
  const [updateListingField] = useUpdateListingFieldMutation();
  const [scoreListing] = useScoreListingMutation();
  const [deleteListings] = useDeleteListingsMutation();
  const [takeScreenshot] = useTakeScreenshotMutation();
  const { data: marketplaces } = useGetMarketPlacesQuery(
    user?.auth ? undefined : skipToken
  );

  const findGroup = (identifier) => {
    return marketplaces[identifier]?.group;
  };

  React.useEffect(() => {
    if (listings && hasRole(user, ["analyst"])) {
      // disable action buttons if conditions not met with selections
      if (selected?.length > 1) {
        let states = 0;
        let brands = new Set();
        let groups = new Set();
        selected.forEach((sel) => {
          let list = listings.list.find((l) => l._id === sel);
          if (list.state === "NEW" || list.state === "SUSPECT") {
            states = states + 1;
          }
          brands.add(list.brand);
          groups.add(findGroup(list.platform));
        });
        let enabled =
          states === selected.length && brands.size === 1 && groups.size === 1;
        setAnalystActionsEnabled(enabled);
      } else {
        setAnalystActionsEnabled(true);
      }
    }

    // eslint-disable-next-line
  }, [selected, listings, marketplaces]);

  const handleClick = () => {
    if (selectedIndex > 0) {
      updateListing("state", options[selectedIndex].value);
    }
    setSelectedIndex(0);
  };

  const handleClickStatus = () => {
    if (statusSelectedIndex > 0) {
      updateListing("status", STATUS_TYPES[statusSelectedIndex].value);
    }
    setStatusSelectedIndex(0);
  };

  const handleMenuItemClick = (_event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleStatusMenuItemClick = (_event, index) => {
    setStatusSelectedIndex(index);
    setStatusOpen(false);
  };

  const handleToggleStatus = () => {
    setStatusOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleStatusClose = (event) => {
    if (
      statusAnchorRef.current &&
      statusAnchorRef.current.contains(event.target)
    ) {
      return;
    }

    setStatusOpen(false);
  };

  const typeSelected = (type) => {
    return (
      listings?.list
        .filter((l) => selected.includes(l._id))
        .filter((s) => s.toi === type).length > 0
    );
  };

  const reasonSelected = (reason) => {
    return (
      listings?.list
        .filter((l) => selected.includes(l._id))
        .filter((s) => s.reason === reason).length > 0
    );
  };

  const stateSelected = (state) => {
    if (state === options[0].text) {
      return false;
    }
    return (
      listings?.list
        .filter((l) => selected.includes(l._id))
        .filter((s) => s.state === state).length > 0
    );
  };

  const statusSelected = (status) => {
    if (status === STATUS_TYPES[0].value) {
      return false;
    }
    return (
      listings?.list
        .filter((l) => selected.includes(l._id))
        .filter((s) => s.listingStatus === status).length > 0
    );
  };

  const handleChange = (field, value) => {
    setConfirm(undefined);
    updateListingField({
      ids: selected,
      field: confirm ? confirm.field : field,
      value: confirm ? confirm.value : value,
      processLike: processLike,
      comment: comment?.trim().length > 0 ? comment : undefined,
    });
    dispatch(setSelectedListings([]));
    setProcessLike(false);
  };

  const updateListing = (field, value) => {
    const complaintArr = ["CR", "TM", "CFT", "DE", "US"];
    if (
      hasRole(user, ["analyst", "admin", "manager", "god"]) &&
      complaintArr.includes(value)
    ) {
      setInfringement({
        selected: listings.list.find((l) => l._id === selected),
        type: value,
      });
    } else if (enableComment && hasRole(user, ["client"])) {
      setConfirm({
        field: field,
        value: value,
        selected: selected,
        setSelected: setSelectedListings,
      });
    } else {
      handleChange(field, value);
    }
  };

  const handleDelete = () => {
    setConfirmDelete(false);
    deleteListings(selected);
    dispatch(setSelectedListings([]));
  };

  const handleTakeScreenshot = () => {
    setConfirmScreenshot(false);
    takeScreenshot(
      listings?.list
        .filter((l) => selected.includes(l._id) && !l.screenshot)
        .map((m) => m.url)
    );
    dispatch(setSelectedListings([]));
  };

  const getButtonText = () => {
    if (selectedIndex > 0) {
      return options[selectedIndex].text;
    }
    let states = listings?.list
      .filter((l) => selected.includes(l._id))
      .map((l) => l.state);
    try {
      if (states.length > 0) {
        return Array.from(new Set(states))
          .map((s) => options.find((o) => o.state === s).text)
          .join(", ");
      }
    } catch (e) {
      return options[0].text;
    }
  };

  const getStatusButtonText = () => {
    if (statusSelectedIndex > 0) {
      return STATUS_TYPES[statusSelectedIndex].text;
    }
    let statuses = listings?.list
      .filter((l) => selected.includes(l._id))
      .map((l) => l.listingStatus);
    try {
      if (statuses.length > 0) {
        return Array.from(new Set(statuses))
          .map((s) => STATUS_TYPES.find((o) => o.value === s).text)
          .join(", ");
      }
    } catch (e) {
      return STATUS_TYPES[0].text;
    }
  };

  if (selected && selected.length > 0) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mt: 3 }}
      >
        <Grid item>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 1 }}
                  id="Comment"
                  checked={enableComment}
                  onChange={(e) => {
                    setEnableComment(e.target.checked);
                  }}
                />
              }
              label="Confirm with comment"
            />
          </FormGroup>
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <Typography variant="overline" display="block" gutterBottom>
            Complaint
          </Typography>
        </Grid>
        <Grid item>
          <ButtonGroup
            orientation="vertical"
            aria-label="vertical button group"
            sx={{ width: 150 }}
          >
            <Button
              key="CR"
              disabled={!analystActionsEnabled}
              variant={
                listings && typeSelected("CR") ? "contained" : "outlined"
              }
              onClick={() => updateListing("state", "CR")}
            >
              Copyright
            </Button>
            <Button
              key="CFT"
              disabled={!analystActionsEnabled}
              variant={
                listings && reasonSelected("COUNTERFEIT")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => updateListing("state", "CFT")}
            >
              Counterfeit
            </Button>
            <Button
              key="TM"
              disabled={!analystActionsEnabled}
              variant={
                listings && reasonSelected("UNAUTHORIZED_MARK")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => updateListing("state", "TM")}
            >
              Trademark
            </Button>
            <Button
              key="DE"
              disabled={!analystActionsEnabled}
              variant={
                listings && typeSelected("DE") ? "contained" : "outlined"
              }
              onClick={() => updateListing("state", "DE")}
            >
              Design
            </Button>
            <Button
              key="US"
              disabled={!analystActionsEnabled}
              variant={
                listings && typeSelected("US") ? "contained" : "outlined"
              }
              onClick={() => updateListing("state", "US")}
            >
              Unauthorized Sale
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <Typography variant="overline" display="block" gutterBottom>
            Other (non-complaint)
          </Typography>
        </Grid>
        <Grid item>
          <React.Fragment>
            <ButtonGroup
              variant={
                getButtonText() === options[0].text ? "outlined" : "contained"
              }
              ref={anchorRef}
              aria-label="additional actions"
            >
              <Button onClick={handleClick} sx={{ width: 150 }}>
                {getButtonText()}
              </Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select additional"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper variant="outlined">
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                          <MenuItem
                            key={option.value}
                            disabled={index === 0}
                            selected={stateSelected(option.state)}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option.text}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        </Grid>
        {hasRole(user, ["admin", "god", "analyst"]) && (
          <React.Fragment>
            <Grid item sx={{ mt: 3 }}>
              <Typography variant="overline" display="block" gutterBottom>
                Listing Status
              </Typography>
            </Grid>
            <Grid item>
              <React.Fragment>
                <ButtonGroup
                  variant={
                    getStatusButtonText() === STATUS_TYPES[0].text
                      ? "outlined"
                      : "contained"
                  }
                  ref={statusAnchorRef}
                  aria-label="status actions"
                >
                  <Button onClick={handleClickStatus} sx={{ width: 150 }}>
                    {getStatusButtonText()}
                  </Button>
                  <Button
                    size="small"
                    aria-controls={statusOpen ? "split-button-menu" : undefined}
                    aria-expanded={statusOpen ? "true" : undefined}
                    aria-label="select status"
                    aria-haspopup="menu"
                    onClick={handleToggleStatus}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: 1,
                  }}
                  open={statusOpen}
                  anchorEl={statusAnchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper variant="outlined">
                        <ClickAwayListener onClickAway={handleStatusClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {STATUS_TYPES.map((option, index) => (
                              <MenuItem
                                key={option.value}
                                disabled={index === 0}
                                selected={statusSelected(option.value)}
                                onClick={(event) =>
                                  handleStatusMenuItemClick(event, index)
                                }
                              >
                                {option.text}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
            </Grid>
          </React.Fragment>
        )}
        <Grid item sx={{ mt: 3 }}>
          <Typography>
            {selected ? `Selected: ${selected.length}` : ""}
          </Typography>
        </Grid>
        {hasRole(user, ["admin", "god"]) && (
          <React.Fragment>
            <Grid item sx={{ mt: 3 }}>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => setConfirmDelete(true)}
              >
                Delete Listing{selected.length > 1 ? "s" : ""}
              </Button>
            </Grid>
            <Grid item sx={{ mt: 3 }}>
              <Button
                variant="outlined"
                startIcon={<AddAPhotoIcon />}
                onClick={() => setConfirmScreenshot(true)}
              >
                Screenshot Listing{selected.length > 1 ? "s" : ""}
              </Button>
            </Grid>
          </React.Fragment>
        )}
        {hasRole(user, ["admin", "god", "analyst"]) && selected.length === 1 && (
          <React.Fragment>
            <Grid item sx={{ mt: 3 }}>
              <Button
                variant="outlined"
                startIcon={<PolicyIcon />}
                onClick={() => setCrawl(true)}
              >
                Crawl Product
              </Button>
            </Grid>
            <Grid item sx={{ mt: 3 }}>
              <Button
                variant="outlined"
                startIcon={<CreditScoreIcon />}
                onClick={() => selected.forEach((s) => scoreListing(s))}
              >
                Score Listing
              </Button>
            </Grid>
          </React.Fragment>
        )}
        {confirm && (
          <ConfirmationDialog
            comment={comment}
            setComment={setComment}
            processLike={processLike}
            setProcessLike={setProcessLike}
            handleChange={handleChange}
            handleClose={() => {
              setConfirm(undefined);
              setComment("");
            }}
            selected={selected}
            field={confirm.field}
            value={confirm.value}
            open={confirm !== undefined}
          />
        )}
        {infringement && (
          <InfringmentDialog
            selected={selected}
            listings={listings.list}
            type={infringement.type}
            handleClose={() => {
              dispatch(setSelectedListings([]));
              setInfringement(undefined);
            }}
          />
        )}
        {confirmDelete && (
          <DeleteConfirmationDialog
            handleChange={handleDelete}
            count={selected.length}
            open={confirmDelete}
            handleClose={() => setConfirmDelete(false)}
          />
        )}
        {confirmScreenshot && (
          <ScreenshotConfirmationDialog
            handleChange={handleTakeScreenshot}
            count={selected.length}
            open={confirmScreenshot}
            handleClose={() => setConfirmScreenshot(false)}
          />
        )}
        {crawl && (
          <CrawlDialog
            open={crawl}
            handleClose={() => setCrawl(false)}
            selected={listings.list.find((l) => l._id === selected[0])}
          />
        )}
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mt: 5 }}
      >
        <Grid item>
          <Typography>Select listings to see actions</Typography>
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <Button
            variant="outlined"
            startIcon={<PolicyIcon />}
            onClick={() => setCrawlUrl(true)}
          >
            Crawl URL
          </Button>
        </Grid>
        {crawlUrl && (
          <CrawlUrlDialog
            open={crawlUrl}
            handleClose={() => setCrawlUrl(false)}
          />
        )}
      </Grid>
    );
  }
};

export default ListingActions;

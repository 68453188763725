import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import ToggleButton from "@mui/material/ToggleButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import FaceIcon from "@mui/icons-material/Face";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import LabelIcon from "@mui/icons-material/Label";
import LinkIcon from "@mui/icons-material/Link";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import ClusterGraph from "../../../components/graphs/Cluster";
import CircularProgress from "@mui/material/CircularProgress";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { styled } from "@mui/material/styles";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import {
  useGetUserBrandsQuery,
  useGetImageClusterQuery,
} from "../../../store/api";

const ExpandMore = styled((props) => {
  // eslint-disable-next-line
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 24,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ImageAnalysis(props) {
  const { data } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [expandLogo, setExpandLogo] = React.useState(false);
  const [expandFacial, setExpandFacial] = React.useState(false);
  const [expandContent, setExpandContent] = React.useState(false);
  const [expandLabel, setExpandLabel] = React.useState(false);
  const [expandSimilar, setExpandSimilar] = React.useState(false);
  const [expandReferences, setExpandReferences] = React.useState(false);
  const [expandGraph, setExpandGraph] = React.useState(false);

  const handleExpandClick = () => {
    if (expanded) {
      setExpandLogo(false);
      setExpandFacial(false);
      setExpandContent(false);
      setExpandLabel(false);
      setExpandSimilar(false);
      setExpandReferences(false);
      setExpandGraph(false);
    } else {
      setExpandLogo(true);
      setExpandFacial(true);
      setExpandContent(true);
      setExpandLabel(true);
      setExpandSimilar(true);
      setExpandReferences(true);
      setExpandGraph(true);
    }
    setExpanded(!expanded);
  };

  const { data: all_brands } = useGetUserBrandsQuery();
  const { data: cluster, isFetching } = useGetImageClusterQuery(
    expandGraph && data ? { url: data.url } : skipToken
  );

  const getExpressions = (face) => {
    let response = [];
    if (face.sorrow > 0) {
      response.push(`Sorrow (${face.sorrow})`);
    }
    if (face.joy > 0) {
      response.push(`Joy (${face.joy})`);
    }
    if (face.anger > 0) {
      response.push(`Anger (${face.anger})`);
    }
    if (face.surprise > 0) {
      response.push(`Surprise (${face.surprise})`);
    }
    return response.join(", ");
  };

  const getQuality = (face) => {
    let response = [];
    if (face.blurred > 0) {
      response.push(`Blurred (${face.blurred})`);
    }
    if (face.underExposed > 0) {
      response.push(`Underexposed (${face.underExposed})`);
    }
    return response.join(", ");
  };

  return (
    <Card sx={{ maxWidth: 600 }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: blue[500], width: 32, height: 32 }}
            aria-label="image"
          >
            <ImageSearchIcon />
          </Avatar>
        }
        action={
          <IconButton
            aria-label="open-image"
            onClick={() => {
              const image_window = window.open("", "_blank");
              image_window.document.write(
                `<html><head></head><body><img src=${data.url} alt="Image" /></body></html>`
              );
            }}
          >
            <OpenInNewIcon />
          </IconButton>
        }
        title="Image Analysis"
        subheader={
          data.created &&
          `${new Intl.DateTimeFormat("en-US", {
            dateStyle: "full",
            timeStyle: "long",
          }).format(new Date(data.created))}`
        }
      />

      <CardContent sx={{ display: "flex" }}>
        <CardMedia
          component="img"
          image={data.url}
          alt={data.url}
          sx={{ width: 240, height: 200 }}
        />
        {data && all_brands && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <Typography variant="button" color="secondary">
              Text Detected
            </Typography>
            <Paper
              sx={{ height: "200px", textAlign: "center", overflow: "scroll" }}
            >
              {data.text &&
                data.text.split("\n").map((str, index) => (
                  <Typography variant="caption" key={index}>
                    {str}
                    <br />
                  </Typography>
                ))}
            </Paper>
          </Stack>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title="Show logos identified">
          <ToggleButton
            sx={{ mr: 2 }}
            size="small"
            value="check"
            selected={expandLogo}
            onChange={() => setExpandLogo(!expandLogo)}
          >
            <BrandingWatermarkIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Show content analysis">
          <ToggleButton
            sx={{ mr: 2 }}
            size="small"
            value="check"
            selected={expandContent}
            onChange={() => setExpandContent(!expandContent)}
          >
            <CenterFocusWeakIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Show labels identified">
          <ToggleButton
            sx={{ mr: 2 }}
            size="small"
            value="check"
            selected={expandLabel}
            onChange={() => setExpandLabel(!expandLabel)}
          >
            <LabelIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Show facial recognition">
          <ToggleButton
            sx={{ mr: 2 }}
            size="small"
            value="check"
            selected={expandFacial}
            onChange={() => setExpandFacial(!expandFacial)}
          >
            <FaceIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Show similar images">
          <ToggleButton
            sx={{ mr: 2 }}
            size="small"
            value="check"
            selected={expandSimilar}
            onChange={() => setExpandSimilar(!expandSimilar)}
          >
            <ContentCopyIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Show website references">
          <ToggleButton
            sx={{ mr: 2 }}
            size="small"
            value="check"
            selected={expandReferences}
            onChange={() => setExpandReferences(!expandReferences)}
          >
            <LinkIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Show image cluster graph">
          <ToggleButton
            size="small"
            value="check"
            selected={expandGraph}
            onChange={() => setExpandGraph(!expandGraph)}
          >
            <ScatterPlotIcon />
          </ToggleButton>
        </Tooltip>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="expand all"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expandLogo} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table size="small" aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={3}>
                    LOGO DETECTION
                  </StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  <TableCell>Logo Identified</TableCell>
                  <TableCell align="center">Certainty</TableCell>
                  <TableCell align="right">Brand Match</TableCell>
                </StyledTableRow>
                {all_brands &&
                  data.logos &&
                  data.logos.map((logo, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{logo.description}</TableCell>
                        <TableCell align="center">
                          {Intl.NumberFormat("en-US", {
                            style: "percent",
                          }).format(logo.score)}
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            color="secondary"
                          >
                            {all_brands.find((b) => b.name === logo.description)
                              ? "✓ matches brand in system"
                              : "None"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableHead>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
      <Collapse in={expandContent} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table size="small" aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={3}>
                    CONTENT ANALYSIS
                  </StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  <TableCell>Entity Identified</TableCell>
                  <TableCell align="right">Certainty</TableCell>
                </StyledTableRow>
                {data.entities &&
                  data.entities.map((entity, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{entity.description}</TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("en-US", {
                            style: "percent",
                          }).format(entity.score)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableHead>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
      <Collapse in={expandLabel} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table size="small" aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={3}>
                    LABEL ANALYSIS
                  </StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  <TableCell>Label Identified</TableCell>
                  <TableCell align="right">Certainty</TableCell>
                  <TableCell align="right">Topicality</TableCell>
                </StyledTableRow>
                {data.labels &&
                  data.labels.map((label, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{label.label}</TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("en-US", {
                            style: "percent",
                          }).format(label.score)}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("en-US", {
                            style: "percent",
                          }).format(label.topicality)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableHead>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
      <Collapse in={expandFacial} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table size="small" aria-label="facial analysis table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={4}>
                    FACIAL RECOGNITION
                  </StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  <TableCell>Expressions (score)</TableCell>
                  <TableCell>Quality (score)</TableCell>
                  <TableCell>Headwear (score)</TableCell>
                  <TableCell>Landmarks Identified</TableCell>
                </StyledTableRow>
                {data.faces &&
                  data.faces.map((face, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{getExpressions(face)}</TableCell>
                        <TableCell>{getQuality(face)}</TableCell>
                        <TableCell>
                          {face.headwear > 0
                            ? `Likelihood: ${face.headwear}`
                            : "None detected"}
                        </TableCell>
                        <TableCell>
                          {face.landmarks
                            .filter((l) => l !== null)
                            .join(", ")
                            .toLowerCase()
                            .replaceAll("_", " ")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableHead>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
      <Collapse in={expandSimilar} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table size="small" aria-label="facial analysis table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={4}>
                    SIMILAR IMAGES
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <ImageList cols={6} rowHeight={164}>
            {data.similarImages &&
              data.similarImages.map((img, index) => (
                <ImageListItem key={index}>
                  <img src={img} alt={img} loading="lazy" />
                </ImageListItem>
              ))}
          </ImageList>
        </CardContent>
      </Collapse>
      <Collapse in={expandGraph} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table size="small" aria-label="facial analysis table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={4}>
                    IMAGE CLUSTER
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          {isFetching && !cluster && <CircularProgress />}
          {cluster && <ClusterGraph cluster={cluster} />}
          {!cluster && (
            <Typography mt={5}>No cluster found for image</Typography>
          )}
        </CardContent>
      </Collapse>
      <Collapse in={expandReferences} timeout="auto" unmountOnExit>
        <CardContent>
          <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table size="small" aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" colSpan={3}>
                    WEBSITE REFERENCES
                  </StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  <TableCell>Website</TableCell>
                  <TableCell>Domain</TableCell>
                  <TableCell align="right">Images</TableCell>
                </StyledTableRow>
                {data.references &&
                  data.references.map((ref, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Link
                            href={ref.url}
                            target="_blank"
                            rel="noopener noreferer"
                            underline="hover"
                          >
                            {ref.title
                              .replaceAll("<b>", "")
                              .replaceAll("</b>", "")}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Link
                            href={`http://${new URL(ref.url).hostname}`}
                            target="_blank"
                            rel="noopener noreferer"
                            underline="hover"
                          >
                            {new URL(ref.url).hostname}
                          </Link>
                        </TableCell>
                        <TableCell align="right">
                          {ref.images.map((image, i) => (
                            <img key={i} src={image} alt={image} height={30} />
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableHead>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
    </Card>
  );
}
